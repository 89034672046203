<template>
  <div class="advcost-board">
    <headerNav :title="title" :leftIcon="true" @leftClick="back" />
    <div class="template-details-box">
      <van-form @submit="onSubmit" validate-trigger="onSubmit">
        <div class="form-top">
          <div class="form-item">
            <div class="form-item-title">
              归属市场
            </div>
            <van-field v-model="editInfo.marketName" name="归属市场" placeholder="请选择" @click="openChooseMarket" clickable
              input-align="right" readonly class="choose-input" :rules="[{ required: true, message: '请选择归属市场' }]">
              <template v-slot:right-icon>
                <div style="display: flex;align-items: center;">
                  <img :src="require('@/assets/xzjt.png')" class="search-icon" alt="">
                </div>
              </template>
            </van-field>
            <!-- 选择市场 -->
            <van-popup v-model="showChooseMarket" position="bottom">
              <van-picker ref="marketPicker" show-toolbar :columns="marketColumns" @confirm="onConfirmChooseMarket"
                :default-index="marketIndex" @cancel="showChooseMarket = false" />
            </van-popup>
          </div>
          <div class="form-item">
            <div class="form-item-title">
              模板名称
            </div>
            <van-field v-model="editInfo.templateName" rows="1" autosize name="模板名称" type="textarea" maxlength="20"
              placeholder="请输入模板名称" class="message-input" show-word-limit
              :rules="[{ required: true, message: '请输入模板名称' }]" />
          </div>
        </div>
        <div class="form-bottom">
          <div class="form-item">
            <div class="form-item-title">
              广告费用比例
              <van-popover v-model="showHint" trigger="click" theme="dark" placement="top" :offset="[0, -10]">
                <div class="hint-text">广告费用占转单产值的比例</div>
                <template #reference>
                  <img :src="require('@/assets/a5.svg')" class="hint-icon" alt="">
                </template>
              </van-popover>
            </div>
            <van-field v-model="editInfo.costScale" type="number" name="广告费比例"
              @input="(e) => xiaoshu(e, 'costScale', 100)" @blur="() => transitionNumber('costScale')"
              :rules="[{ required: true, message: '请输入广告费比例' }]" placeholder="请输入" input-align="right">
              <template v-slot:right-icon>
                <span class="input-right-text">%</span>
              </template>
            </van-field>
          </div>
          <div class="form-item">
            <div class="form-item-title">
              均单产值
            </div>
            <van-field v-model="editInfo.avgValue" type="number" name="均单产值"
              @input="(e) => xiaoshu(e, 'avgValue', 1000000)" @blur="() => transitionNumber('avgValue')" placeholder="请输入"
              :rules="[{ required: true, message: '请输入均单产值' }]" input-align="right">
              <template v-slot:right-icon>
                <span class="input-right-text">元</span>
              </template>
            </van-field>
          </div>
          <div class="form-item">
            <div class="form-item-title">
              转单率
            </div>
            <van-field v-model="editInfo.transferScale" type="number" name="转单率"
              @input="(e) => xiaoshu(e, 'transferScale', 100)" @blur="() => transitionNumber('transferScale')"
              :rules="[{ required: true, message: '请输入转单率' }]" placeholder="请输入" input-align="right">
              <template v-slot:right-icon>
                <span class="input-right-text">%</span>
              </template>
            </van-field>
          </div>
          <div class="form-item">
            <div class="form-item-title">
              签单率
            </div>
            <van-field v-model="editInfo.signScale" type="number" name="签单率" @input="(e) => xiaoshu(e, 'signScale', 100)"
              @blur="() => transitionNumber('signScale')" :rules="[{ required: true, message: '请输入签单率' }]"
              placeholder="请输入" input-align="right">
              <template v-slot:right-icon>
                <span class="input-right-text">%</span>
              </template>
            </van-field>
          </div>
          <div class="form-item">
            <div class="form-item-title">
              到店率
            </div>
            <van-field v-model="editInfo.shopScale" type="number" name="到店率" @input="(e) => xiaoshu(e, 'shopScale', 100)"
              @blur="() => transitionNumber('shopScale')" :rules="[{ required: true, message: '请输入到店率' }]"
              placeholder="请输入" input-align="right">
              <template v-slot:right-icon>
                <span class="input-right-text">%</span>
              </template>
            </van-field>
          </div>
          <div class="form-item">
            <div class="form-item-title">
              有效率
            </div>
            <van-field v-model="editInfo.validScale" type="number" name="有效率"
              @input="(e) => xiaoshu(e, 'validScale', 100)" @blur="() => transitionNumber('validScale')"
              :rules="[{ required: true, message: '请输入有效率' }]" placeholder="请输入" input-align="right">
              <template v-slot:right-icon>
                <span class="input-right-text">%</span>
              </template>
            </van-field>
          </div>
        </div>
        <div class="form-btn">
          <van-button block type="info" color="#3975C6" native-type="submit" :disabled="getDisabled()">确认</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import api from '../../../api/api.js'
import { Toast } from 'vant';
import { isObjectEqual } from '@/utils/index'
export default {
  data () {
    return {
      originalData: {},//原始数据
      editInfo: {},
      showChooseMarket: false,
      marketColumns: [],
      marketIndex: 0,
      showHint: false,
      title: ''
    }
  },
  methods: {
    //返回
    back () {
      this.$router.push({ name: 'TaskAdvcostTemplateLibrary' })
    },
    //获取模板信息
    async getTemplateDetails () {
      let res = await api.getTemplateDetails(this.$route.params.id)
      this.originalData = JSON.parse(JSON.stringify(res.data))
      this.editInfo = res.data
      let keys = ['costScale', 'avgValue', 'transferScale', 'signScale', 'shopScale', 'validScale']
      keys.forEach(e => {
        this.editInfo[e] = Number(this.editInfo[e]).toFixed(2)
      })
      this.marketColumns.forEach((e, i) => {
        if (e.code == this.editInfo.marketCode) {
          this.marketIndex = i
        }
      })
    },
    //提交
    async onSubmit () {
      if (this.$route.params.id != '0') {
        let res = await api.editTemplate(this.editInfo)
        if (res.code == 200) {
          Toast('编辑成功!');
          this.$router.push({ name: 'TaskAdvcostTemplateLibrary' })
        }
      } else {
        let res = await api.addTemplate(this.editInfo)
        if (res.code == 200) {
          Toast('添加成功!');
          this.$router.push({ name: 'TaskAdvcostTemplateLibrary' })
        }
      }

    },
    //打开选择市场弹窗
    openChooseMarket () {
      this.showChooseMarket = true
      this.$refs.marketPicker && this.$refs.marketPicker.setValues([this.editInfo.marketName])
    },
    //选择市场
    onConfirmChooseMarket (value) {
      this.editInfo.marketCode = value.code
      this.editInfo.marketName = value.name
      this.showChooseMarket = false
    },
    // 获取市场
    async getMarketCode () {
      let params = {
        Q_typeCode_IN: 'DICT771486',
      }
      let res = await api.getDictionariesList(params)
      res.data.forEach(e => {
        if (e.status != 1) {
          e.text = e.name
          this.marketColumns.push(e)
        }
      })
    },
    //判断数据是否改变 获取禁用状态
    getDisabled () {
      return isObjectEqual(this.editInfo, this.originalData)
    },
    xiaoshu (value, key, max) {
      value = value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
      value = value.replace(/^\./g, "");  //必须保证第一个为数字而不是.
      value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //不能重复出现点
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
      if (value.indexOf(".") < 0 && value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value);
      }
      if (value > max) value = max
      this.editInfo[key] = value
    },
    transitionNumber (key) {
      (this.editInfo[key] !== undefined) && (this.editInfo[key] = Number(this.editInfo[key]).toFixed(2))
    },
  },
  mounted () { },
  created () {
    let _this = this
    this.getMarketCode().then(() => {
      if (this.$route.params.id != '0') {
        this.title = '模板详情'
        _this.getTemplateDetails()
      } else {
        this.title = '模板设置'
      }
    })
  }
}
</script>

<style scoped lang="scss">
.template-details-box {
  height: calc(100vh - 50px);
  background: #F5F5F5;

  .form-top,
  .form-bottom {
    background: #FFFFFF;
    border-radius: 8px;
  }

  .form-bottom {
    margin-top: 8px;
  }

  .form-item {
    border-bottom: 1px solid #EBEDF0;
    margin: 0 17px;
    min-height: 58px;
    display: flex;
    // align-items: center;

    .form-item-title {
      font-size: 17px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: 38%;
      line-height: 58px;
      text-align: left;
      padding-left: 10px;
      display: flex;
      align-items: center;

      &::after {
        content: '*';
        position: absolute;
        left: 0;
        color: #FF6259;
        font-size: 17px;
      }
    }
  }

  .form-btn {
    margin: 19px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;

    .van-button {
      font-size: 18px;
    }
  }

  .hint-icon {
    width: 16px;
    margin-left: 2px;
  }

  .hint-popover {
    height: 16px;
    display: flex;
  }
}

.hint-text {
  line-height: 25px;
  padding: 0 10px;
}

::v-deep {
  .van-field {
    width: 62%;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  .van-field__error-message {
    text-align: right;
  }

  .van-field__body {
    height: 58px;
  }

  .van-cell {
    line-height: none;
    padding: 0;
  }

  .choose-input {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding-right: 0;

    img {
      width: 20px;
    }

  }

  .message-input {
    padding: 0;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;

    .van-field__body {
      height: auto;
    }

    &>div {
      display: flex;
      justify-content: end;
      // flex-wrap: wrap;
    }

    textarea {
      text-align: right;
      padding-right: 8px;
    }

    .van-field__word-limit {
      font-size: 17px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #000000;
      display: flex;
      align-items: center;
      margin-top: 0;
    }

    .van-field__body {
      width: 80%;
    }
  }

  .input-right-text {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;
  }

  .van-field__word-limit {
    font-size: 14px !important;
  }
}
</style>